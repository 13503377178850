import { slideoutStore } from '@atomic-ui/slideout';
import { locations } from '@common-vue/locator/src/store';
import {
  UPDATE_SORTBY_SELECTED, REPLACE_SELECTED_VALUES, ADD_CHECKBOX_VALUES, REMOVE_CHECKBOX_VALUES_BY_VALUE, ADD_FACET_OPTIONS, ADD_SORT_BY_OPTIONS,
  REMOVE_CUSTOM_CHECKBOX_VALUES, SET_DESELECTION_PILLS_ORDER_NAMES, CLEAR_ALL_SELECTED_VALUES,
} from './mutations';
import GET_ALL_SELECTED_CRITERIA from './actions';

/*
facet type
MULTISELECTDEFAULT (e.g. Item Type)
MULTISELECTRANGE (e.g. Price)
MULTISELECTFLYOUT (e.g. Brand)
MULTISELECTBUTTON (e.g. Size Type)
MULTISELECTSWATCH (e.g. Color)
*/
export default {
  // namespaced: true, // disable namespace to prevent nested namespaced in slideout
  state() {
    return {
      facets: [],
      sortBy: {
        selectedValue: null,
        sortByOptions: [],
      },
      deselectionPills: {
        orderNames: [],
      },
    };
  },
  modules: {
    slideoutStore,
    locations,
  },
  getters: {
    sortBy(state) {
      return state.sortBy;
    },
    facets(state) {
      return state.facets;
    },
    facetByName: (state) => (name) => state.facets.filter((facet) => facet.name === name)[0],
    allSelectedCriteria(state, getters) {
      return {
        sortBy: getters.sortBy.selectedValue,
        facets: getters.facets
          .filter((facet) => facet.selectedValues.length > 0)
          .map((facet) => ({
            ...facet,
          }))
          .sort((a, b) => (
            /* istanbul ignore next */
            ((a.selectionOrder === undefined) ? 10000 : a.selectionOrder)
            - ((b.selectionOrder === undefined) ? 10000 : b.selectionOrder)
          )),
      };
    },
    getDeselectionPillsNamesOrder(state) {
      return state.deselectionPills.orderNames;
    },
  },
  mutations: {
    [ADD_FACET_OPTIONS](state, value) {
      state.facets = value.facets;
    },
    [ADD_SORT_BY_OPTIONS](state, value) {
      const selectedSortOption = value.sortBy.find((element) => element.isSelected);
      if (selectedSortOption) {
        state.sortBy.selectedValue = selectedSortOption.value;
      } else {
        state.sortBy.selectedValue = value.sortBy[0].value;
      }
      state.sortBy.sortByOptions = value.sortBy;
    },
    [UPDATE_SORTBY_SELECTED](state, value) {
      state.sortBy.selectedValue = value;
    },
    [REPLACE_SELECTED_VALUES](state, criteria) {
      const facet = state.facets.filter((_facet) => _facet.name === criteria.name);
      if (facet.length > 0) {
        facet[0].selectedValues = criteria.selectedValues;
        facet[0].selectionOrder = criteria.selectionOrder;
      } else if (criteria.selectedValues?.length) {
        // in case facet is missing and there are selected values
        state.facets.push(criteria);
      }
    },
    [CLEAR_ALL_SELECTED_VALUES](state) {
      state.facets.forEach((facet) => {
        facet.selectedValues = [];
      });
    },
    [ADD_CHECKBOX_VALUES](state, criteria) {
      const facet = state.facets.filter((_facet) => _facet.name === criteria.name);
      if (facet.length > 0) {
        facet[0].values.push(criteria.newValue);
      }
    },
    [REMOVE_CHECKBOX_VALUES_BY_VALUE](state, criteria) {
      const facet = state.facets.filter((_facet) => _facet.name === criteria.name);
      if (facet.length > 0) {
        facet[0].values = facet[0].values.filter((value) => value.value !== criteria.value);
      }
    },
    [REMOVE_CUSTOM_CHECKBOX_VALUES](state, { name, isCustomOptionFunc }) {
      const facet = state.facets.filter((_facet) => _facet.name === name);
      if (facet.length > 0) {
        facet[0].values = facet[0].values.filter((value) => !isCustomOptionFunc(value));
      }
    },
    [SET_DESELECTION_PILLS_ORDER_NAMES](state, { orderNames }) {
      state.deselectionPills.orderNames = orderNames;
    },
  },
  actions: {
    [GET_ALL_SELECTED_CRITERIA]() {
      // Use for consumer to subscribe this action
    },
  },
};
