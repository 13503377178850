import { ref } from 'vue';
import { pageTypes, pageTypesLiteral as PageTypesLiteral } from '@utils/constants';
import useMetaPageType from './useMetaPageType';
import useMetaContext from './useMetaContext';

const isBrowseRef = ref(true);
const isSplashRef = ref(false);

export default function usePageType(aMetaObj) {
  const pageTypeRef = aMetaObj ? useMetaPageType(aMetaObj) : useMetaPageType();
  const contextRef = aMetaObj ? useMetaContext(aMetaObj) : useMetaContext();
  let sitePageType = '';
  let isSubSplash = false;
  let isSplash = false;
  let isBrowse = false;
  let isSearch = false;
  let isBrandIndex = false;
  let pageTypeLiteral = '';
  let isZeroSearchResults = false;

  const pageType = pageTypeRef.value;
  const context = contextRef.value;
  const giftEligibleBrowsePage = pageType?.giftEligibleBrowsePage;
  /* istanbul ignore if  */
  if (pageType?.subSplash || pageType?.brandPage || (context?.navigationType === 'BROWSE')) {
    sitePageType = pageTypes.SUBSPLASH;
    isSubSplash = true;
    isBrowseRef.value = true;
    pageTypeLiteral = PageTypesLiteral.subSplash;
  }
  /* istanbul ignore if  */
  if (pageType?.search || pageType?.zeroSearchResults) {
    isZeroSearchResults = pageType?.zeroSearchResults;
    if (pageType?.giftEligibleBrowsePage) {
      sitePageType = pageTypes.BROWSE;
      isBrowse = true;
      isBrowseRef.value = true;
      pageTypeLiteral = PageTypesLiteral.browse;
    } else {
      sitePageType = pageTypes.SEARCH;
      isSearch = true;
      isBrowseRef.value = false;
      pageTypeLiteral = PageTypesLiteral.search;
    }
  }
  /* istanbul ignore if  */
  if (pageType?.browse) {
    sitePageType = pageTypes.BROWSE;
    isBrowse = true;
    isBrowseRef.value = true;
    pageTypeLiteral = PageTypesLiteral.browse;
  }
  /* istanbul ignore if  */
  if (pageType?.catSplash) {
    sitePageType = pageTypes.SPLASH;
    isSplash = true;
    isSplashRef.value = true;
    isBrowseRef.value = false;
    pageTypeLiteral = PageTypesLiteral.catSplash;
  }
  /* istanbul ignore if  */
  if (pageType?.brandIndex) {
    sitePageType = pageTypes.BRANDINDEX;
    isBrandIndex = true;
    isSplashRef.value = true;
    isBrowseRef.value = false;
    pageTypeLiteral = PageTypesLiteral.brandIndex;
  }

  return {
    sitePageType,
    isSubSplash,
    // TODO replace isSplash and isBrowse by ref properties
    isSplash,
    isBrowse,
    isSearch,
    isBrandIndex,
    pageTypeLiteral,
    isZeroSearchResults,
    giftEligibleBrowsePage,
    isBrowseRef,
    isSplashRef,
  };
}
